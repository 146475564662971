<template>
  <div class="app-container">
    <div class="page-title">
      <h2>{{ $t('alert.alerts') }}</h2>
    </div>
    <el-card v-if="checkRole(['admin'])" class="box-card filter-card just-for-admin">
      <div slot="header" class="clearfix">
        <span>{{ $t('general.filter') }}</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="initSearch">{{ $t('general.init') }}</el-button>
      </div>
      <div class="filter-container">
        <el-radio v-model="activeList" label="actives" border @change="getSubAlertsList('indexactive')">{{ $t('general.F_actives') }}</el-radio>
        <el-radio v-model="activeList" label="desactives" border @change="getSubAlertsList('indexdesactive')">{{ $t('general.F_desactives') }}</el-radio>
        <el-radio v-model="activeList" label="disponibles" border @change="handleFilterAlerts">{{ $t('general.disponibles') }}</el-radio>
        <el-radio v-model="activeList" label="corbeille" class="danger-radio" border @change="getSubAlertsList('indexinvisible')">{{ $t('general.corbeille') }}</el-radio>
      </div>
    </el-card>

    <el-card class="box-card">
      <div class="filter-container">
        <el-input v-model="query.keyword" clearable :placeholder="$t('general.keyword')" class="wd-200 mr-15 filter-item" @keyup.enter.native="handleFilterAlerts" />
        <el-select v-model="query.processing" clearable class="filter-item wd-200 mr-15 filter-item" :placeholder="$t('general.processing')" style="width: 100%;" @change="handleFilterAlerts">
          <el-option v-for="(processing, index) in processings" :key="index" :label="processing | uppercaseFirst" :value="processing" />
        </el-select>
        <el-button v-waves class="mr-15 filter-item" type="primary" icon="el-icon-search" @click="handleFilterAlerts">
          {{ $t('general.search') }}
        </el-button>
        <el-button v-waves :loading="downloading" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload">
          {{ $t('general.export') }}
        </el-button>
        <!--el-button v-if="checkPermission(['create alert'])" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="handleCreateAlert">
          {{ $t('general.add') }}
        </el-button-->
      </div>
      <el-table v-loading="loading" :data="list" border fit highlight-current-row style="width: 100%">
        <!--el-table-column align="center" label="ID" width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.id }}</span>
          </template>
        </el-table-column-->

        <el-table-column align="center" :label="$t('alert.subject')" width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.subject }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('alert.body')" min-width="300px">
          <template slot-scope="scope">
            <span>{{ scope.row.body.substring(0, 100) }} ...</span>
          </template>
        </el-table-column>
        <!--el-table-column v-if="!query.user_id" align="center" :label="$t('user.user')" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.user.name }}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="activeList!='corbeille' && checkPermission(['activate alert'])" align="center" :label="$t('general.status')" width="100">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
              :value="scope.row.is_active"
              :disabled="!scope.row.is_visible"
              @change="setAlertStatus(scope.row.id,'active')"
            />
          </template>
        </el-table-column-->

        <el-table-column align="center" :label="$t('general.processing')" width="100">
          <template slot-scope="scope">
            <el-tag :type="getprocessingColor(scope.row.processing)">{{ scope.row.processing | uppercaseFirst }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column v-if=" checkRole(['admin']) && (activeList=='corbeille' || query.keyword)" align="center" :label="$t('general.restaurer')" width="100">
          <template slot-scope="scope">
            <el-switch
              v-if="!scope.row.is_visible"
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_visible"
              @change="setAlertStatus(scope.row.id,'visible')"
            />
          </template>
        </el-table-column>
        <el-table-column v-if="checkPermission(['edit alert','hide alert','delete alert'])" align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="150">
          <template slot-scope="scope">
            <el-button v-if="scope.row.is_visible && checkPermission(['edit alert'])" type="primary" size="small" icon="el-icon-view" style="margin-right:10px;" @click="handleEditAlert(scope.row.id);" />
            <el-button v-if="checkPermission(['hide alert','delete alert'])" slot="reference" type="danger" size="small" icon="el-icon-delete" style="margin-right:10px;" @click="handleDeleteAlert(scope.row.id, scope.row.is_visible, scope.row.subject);" />
          </template>
        </el-table-column>
      </el-table>

      <pagination v-if="total > 0" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getAlertsList" />

      <!-- ======= REGION USER DIALOG ======= -->
      <el-drawer
        :title="$t('alert.alert')"
        :visible.sync="dialogCreateAlert"
        :before-close="handleCloseAlertCreateDraw"
        :loading="alertCreating"
        size="30%"
      >
        <div class="content-inputs" style="padding:30px">
          <div class="alert-subject margin-top">
            <h3>{{ $t('alert.subject') }}</h3>
            <p>
              {{ currentAlert.subject }}
            </p>
          </div>
          <div class="alert-body margin-top">
            <h3>{{ $t('alert.body') }}</h3>
            <p class="body-text">
              {{ currentAlert.body }}
            </p>
          </div>
          <el-divider />
          <el-card class="box-card">
            <el-form ref="alertForm" :rules="rules" :model="currentAlert" label-position="left">
              <el-form-item :label="$t('general.processing')" prop="processing">
                <el-radio-group v-model="currentAlert.processing">
                  <el-radio
                    v-for="(processing, idex) in processings"
                    :key="idex"
                    :label="processing"
                    :class="'radio-'+getprocessingColor(processing)"
                  > {{ processing | uppercaseFirst }} </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
            <span>
              <div style="text-align:right;">
                <el-button plain type="danger" @click="dialogCreateAlert=false">
                  {{ $t('general.close') }}
                </el-button>
                <el-button type="primary" :loading="alertCreating" @click="editing ? updateAlert() : createAlert()">
                  {{ alertCreating ? $t('general.saving') : $t('general.save') }}
                </el-button>
              </div>
            </span>
          </el-card>
        </div>
      </el-drawer>

      <!--el-dialog
        :title="editing ? $t('alert.edit') + ' ' + currentAlert.name : $t('alert.AddAlert')"
        :visible.sync="dialogCreateAlert"
        :before-close="handleCloseAlertCreateDraw"
        :loading="alertCreating"
        width="70%"
      >
        <div class="content-inputs">
          <el-form ref="alertForm" :rules="rules" :model="currentAlert" label-position="left">
            <el-form-item :label="$t('general.processing')" prop="processing">
              <el-select v-model="currentAlert.processing" class="filter-item" :placeholder="$t('general.processing')" style="width: 100%;" prop="processing">
                <el-option
                  v-for="(processing, idex) in processings"
                  :key="idex"
                  :label="processing | uppercaseFirst"
                  :value="processing"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('alert.subject')" prop="subject">
              <el-input v-model="currentAlert.subject" />
            </el-form-item>
            <el-form-item :label="$t('alert.body')" prop="body">
              <el-input v-model="currentAlert.body" type="textarea" />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="dialogCreateAlert=false">
              {{ $t('permission.cancel') }}
            </el-button>
            <el-button type="primary" :loading="alertCreating" @click="editing ? updateAlert() : createAlert()">
              {{ alertCreating ? $t('general.saving') : $t('general.save') }}
            </el-button>
          </div>
        </span>
      </el-dialog-->

      <!-- ======= REGION USER DIALOG ======= -->

    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
const alertResource = new Resource('alerts');
export default {
  name: 'AlertsList',
  components: { Pagination },
  directives: { waves, permission, role },
  data() {
    return {
      list: null,
      total: 0,
      loading: false,
      downloading: false,
      alertCreating: false,
      editing: false,
      activeList: 'disponibles',
      processings: this.$processingStatus,
      query: {
        page: 1,
        limit: this.$limit,
        keyword: '',
        processing: 'En attente',
      },
      currentAlert: {},
      dialogCreateAlert: false,
      currentAlertId: 0,
      rules: {
        subject: [{ required: true, message: this.$t('alert.SubjectRequired'), trigger: 'blur' }],
        body: [{ required: true, message: this.$t('alert.BodyRequired'), trigger: 'blur' }],
      },
    };
  },
  computed: {
  },
  created() {
    this.resetNewAlert();
    this.getAlertsList();
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    handleCloseAlertCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    cancelAlertForm() {
      this.$refs.AlertCreationDrawer.closeDrawer();
    },
    getprocessingColor(processing){
      var color = 'primary';
      if (processing === 'waiting'){
        color = 'warning';
      } else if (processing === 'finished'){
        color = 'success';
      } else if (processing === 'canceled'){
        color = 'info';
      }
      return color;
    },
    initSearch(){
      this.query = {
        page: 1,
        limit: this.$limit,
        keyword: '',
        processing: 'waiting',
      };
      this.activeList = 'disponibles';
      this.handleFilterAlerts();
    },
    async getAlertsList() {
      this.loading = true;
      const { limit, page } = this.query;
      const { data, meta } = await alertResource.list(this.query);
      this.list = data;
      this.list.forEach((element, index) => {
        element['index'] = (page - 1) * limit + index + 1;
      });
      this.total = meta.total;
      this.loading = false;
    },
    async getSubAlertsList(subUrl) {
      this.loading = true;
      const { data } = await alertResource.sublist('/' + subUrl, this.query);
      this.list = data;
      this.loading = false;
    },
    handleFilterAlerts() {
      this.query.page = 1;
      this.getAlertsList();
    },
    handleCreateAlert() {
      this.editing = false;
      this.resetNewAlert();
      this.dialogCreateAlert = true;
      this.$nextTick(() => {
        this.$refs['alertForm'].clearValidate();
      });
    },
    handleEditAlert(id){
      this.editing = true;
      this.resetNewAlert();
      this.currentAlert = this.list.find(abonne => abonne.id === id);
      this.dialogCreateAlert = true;
      this.$nextTick(() => {
        this.$refs['alertForm'].clearValidate();
      });
    },
    async setAlertStatus(id, type){
      const data = await alertResource.get('toogle_' + type + '/' + id);
      if (data.success){
        this.$message({
          type: 'success',
          message: this.$t('general.statusUpdated'),
        });
        this.initSearch();
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    handleDeleteAlert(id, visible, name) {
      this.$confirm(this.$t('alert.deleteWarn') + ' ' + name + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        if (!visible && (checkRole(['admin']) || checkPermission(['delete alert']))) {
          this.$swal({
            title: this.$t('general.SureQuestion'),
            text: this.$t('general.irrversibleMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('general.ouiSupprimer'),
          }).then((result) => {
            if (result.isConfirmed) {
              alertResource.destroy(id).then(response => {
                this.$message({
                  type: 'success',
                  message: this.$t('alert.suppressionEffectue'),
                });
                this.initSearch();
              }).catch(error => {
                console.log(error);
              });
            }
          });
        } else {
          this.setAlertStatus(id, 'visible');
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
    createAlert() {
      this.$refs['alertForm'].validate((valid) => {
        if (valid) {
          this.currentAlert.roles = [this.currentAlert.role];
          this.alertCreating = true;
          alertResource
            .store(this.currentAlert)
            .then(async(response) => {
              this.$message({
                message: this.$t('alert.NewAlert') + ' ' + this.currentAlert.subject + ' ' + this.$t('alert.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentAlertId = response.data.id;
              this.resetNewAlert();
              this.dialogCreateAlert = false;
              this.handleFilterAlerts();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.alertCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    updateAlert() {
      this.$refs['alertForm'].validate((valid) => {
        if (valid) {
          this.alertCreating = true;
          alertResource.update(this.currentAlert.id, this.currentAlert).then(response => {
            this.resetNewAlert();
            this.handleFilterAlerts();
            this.$message({
              message: this.$t('alert.isupdatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.dialogCreateAlert = false;
            this.editing = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.alertCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    resetNewAlert() {
      this.currentAlert = {
        subject: '',
        description: '',
      };
    },
    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['ID', this.$t('alert.subject'), this.$t('alert.body')];
        const filterVal = ['id', 'subject', 'body'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'Alerts list SOYCAIN_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
